// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: "auth-vue-frontend.firebaseapp.com",
  projectId: "auth-vue-frontend",
  storageBucket: "auth-vue-frontend.appspot.com",
  messagingSenderId: "269633308507",
  appId: "1:269633308507:web:4075d6019547bc4c1fbc7e",
  measurementId: "G-SW6JBWTLFL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export { app, analytics, firebaseConfig, auth, functions, db };
