<template>
  <div class="conditions">
    <h1>Conditions Générales de Vente</h1>
    <p>Date de dernière mise à jour : 24/06/2023</p>

    <section>
    <h2>Article 1 – Mentions Légales</h2>
    <p>Le présent site, accessible à l’URL <a href="https://www.query-juriste.com">https://www.query-juriste.com</a> (« Query Juriste »), est édité par:</p>
    <p>Query Juriste, société au capital de 100 euros, inscrite au R.C.S. Greffe du Tribunal de Commerce de Paris, sous le numéro 929 322 361, dont le siège social est situé au 37 rue Rousselet, 75007 Paris, représenté par Etienne Le Guay dûment habilité.</p>
    <p>Le numéro individuel TVA de l’Exploitant est [insérer numéro de TVA].</p>
    <p>Le Site est hébergé par la société Microsoft Azur, situé au 6011 NE 36th Way, Redmond 98052, WA, téléphone : 0157-324-218.</p>
    <p>Le Directeur de la publication du Site est Etienne Le Guay.</p>
    <p>L’Exploitant peut être joint au numéro de téléphone suivant +33782178073 et à l’adresse électronique suivante : etienneleguay@query-juriste.com.</p>
    </section>

    <section>
    <h2>Article 2 – Dispositions Générales Relatives Aux Présentes Conditions Générales</h2>
    <p>Les Conditions Générales de Vente (les « CGV ») sont applicables exclusivement à la vente en ligne des services proposés par l’Exploitant sur le site internet.</p>
    <p>Les CGV sont mises à la disposition des clients sur le site où elles sont directement consultables et peuvent également lui être communiquées sur simple demande par tout moyen.</p>
    <p>Les CGV sont opposables au client qui reconnaît, en cochant une case prévue à cet effet, en avoir eu connaissance et les avoir acceptées avant de passer commande. La validation de la commande par sa confirmation vaut adhésion par l'acheteur aux CGV en vigueur au jour de la commande dont la conservation et la reproduction sont assurées par l’Exploitant.</p>
    <p>Les CGV sont également systématiquement envoyées avec toute confirmation de commande transmise au Client par l’Exploitant. Tout Client est par conséquent réputé avoir pris connaissance et accepté sans réserve l'intégralité des dispositions des CGV, qui s'appliqueront à toutes prestations accomplies par l'Exploitant. Les CGV prévaudront sur toutes clauses et conditions contraires pouvant figurer sur les conditions générales d'achat ou tout autre document émanant du Client. Toutes conditions contraires posées par le Client seront donc, à défaut d'acceptation expresse par l'Exploitant, inopposables à ce dernier. L’Exploitant se réserve cependant le droit de déroger à certaines clauses des CGV en fonction des négociations menées avec le Client, par l’établissement de conditions de vente particulières (notamment par la conclusion d’un contrat de prestation de services) qui seront approuvées par le Client.</p>
    </section>

    <section>
      <h2>Article 3 – Description des Services</h2>
      <p>Le Site est un site de vente en ligne de services d’aide juridique. C’est une plateforme dédiée aux juristes, permettant la recherche sémantique avancée dans la jurisprudence française grâce à une base de données vectorielles. Elle offre une interface intuitive pour accéder rapidement aux décisions de justice pertinente facilitant ainsi la prise de décisions juridique et un travail éclairé ouvert à toute personne physique ou morale utilisant le Site Query Juriste. Les Services présentés sur le Site font chacun l'objet d'un descriptif mentionnant leurs caractéristiques essentielles. Les photographies illustrant les Services ne constituent pas un document contractuel. Les Services sont conformes aux prescriptions du droit français en vigueur.</p>
      <p>Le Client demeure responsable des modalités et des conséquences de son accès au Site notamment par l’Internet. Cet accès peut impliquer le paiement de frais à des prestataires techniques tels que notamment des fournisseurs d’accès à l’Internet, lesquels demeurent à sa charge. En outre, le Client devra fournir et être entièrement responsable des équipements nécessaires afin de se connecter au Site.</p>
      <p>Le Client reconnaît avoir vérifié que la configuration informatique qu’il utilise est sécurisée et en état de fonctionnement.</p>
    </section>

    <section>
      <h2>Article 4 – Création de l’Espace Client</h2>
      <p>Pour passer une commande sur le Site, le Client doit au préalable créer son espace client personnel. Une fois créé, pour y accéder, le Client doit s’identifier en utilisant son identifiant et son mot de passe secret, personnel et confidentiel. Il appartient au Client de ne pas communiquer son identifiant et son mot de passe conformément aux dispositions de l’article DONNEES PERSONNELLES des présentes. Chaque Client s'engage à conserver une stricte confidentialité sur les données, en particulier identifiant et mot de passe, lui permettant d'accéder à son espace client, le Client reconnaissant être le seul responsable de l'accès au Service par le biais de son identifiant et de son mot de passe, sauf fraude avérée. Chaque Client s'engage en outre à informer sans délai l’Exploitant dans l'hypothèse d'une perte, d'un détournement ou de l'utilisation frauduleuse de son identifiant et/ou mot de passe.</p>
      <p>Après la création de son espace client personnel, le Client recevra un email lui confirmant la création de son espace client.</p>
      <p>Le Client s'engage lors de son inscription à :</p>
      <ul>
        <li>délivrer des informations réelles, exactes, à jour au moment de leur saisie dans le formulaire d'inscription du service, et notamment à ne pas utiliser de faux noms ou adresses, ou encore des noms ou adresses sans y être autorisé.</li>
        <li>maintenir à jour les données d'inscriptions en vue de garantir en permanence leur caractère réel, exact et à jour.</li>
      </ul>
      <p>Le Client s'engage en outre à ne pas rendre disponible ou distribuer des informations illicites ou répréhensibles (telles que des informations diffamatoires ou constitutive d'usurpation d'identité) ou encore nuisibles (telles que les virus). Dans le cas contraire, l’Exploitant sera en mesure de suspendre ou de résilier l'accès du Client au Site à ses torts exclusifs.</p>
    </section>

    <section>
      <h2>Article 5 – Commandes</h2>
      <p>Au moment de passer une commande, le Client doit sélectionner les Services choisis, les ajouter à son panier en indiquant les Services sélectionnés et les quantités souhaitées. Le Client a la possibilité de vérifier le détail de sa commande et son prix total, et de revenir aux pages précédentes pour éventuellement corriger le contenu de son panier, avant de le valider.</p>
      <p>Le Client s’engage à lire les Conditions Générales alors en vigueur avant de les accepter et de confirmer les modalités et les éventuels frais de livraison et de rétractation préalable au paiement de sa commande. La confirmation de la commande entraîne acceptation des CGV et forme le contrat.</p>
      <p>L'attention du Client est particulièrement attirée sur le mode d'acceptation de la commande passée sur le Site. Lorsque le Client passe sa commande, il doit confirmer celle-ci par la technique du « double-clic ».</p>
    </section>

    <section>
      <h2>Article 6 – Modalités de Paiement et Sécurisation</h2>
      <p>Le Client reconnaît expressément que toute commande effectuée sur le Site est une commande avec obligation de paiement, qui nécessite le paiement d’un prix contre la fourniture du Service commandé.</p>
      <p>Les commandes peuvent être payées en ayant recours à l’un des modes de paiement suivants : paiement par carte bancaire, paiement par portefeuille électronique (type Paypal).</p>
      <p>Le Client est informé que la mise à disposition du Service ne pourra être effectuée avant le parfait encaissement par l’Exploitant des sommes dues par le Client.</p>
    </section>

    <section>
      <h2>Article 7 – Paiement du Prix</h2>
      <p>Le prix des Services en vigueur lors de la commande est indiqué en euros toutes taxes comprises (TTC) sur le Site. En cas de promotion, l’Exploitant s'engage à appliquer le prix promotionnel à toute commande passée durant la période de la publicité faite pour la promotion.</p>
      <p>Le prix est payable en euros (€) exclusivement.</p>
      <p>Le prix est exigible en totalité après confirmation de la commande. Les prix proposés comprennent les rabais et ristournes que l’Exploitant serait amené à octroyer.</p>
      <p>Le montant total dû par le Client et son détail sont indiqués sur la page de confirmation de commande.</p>
    </section>

    <section>
      <h2>Article 8 – Droit de Rétractation</h2>
      <p>Le Client bénéficiera d’un délai de rétractation de quatorze (14) jours calendaires à compter de la conclusion du contrat, conformément aux articles L. 221-18 et suivants du Code de la consommation.</p>
      <p>Si le Client souhaite que l'exécution d'une prestation de services commence avant la fin du délai de rétractation, l’Exploitant recueille sa demande expresse par tout moyen.</p>
      <p>Le Client qui a exercé son droit de rétractation pour une prestation dont l'exécution a commencé, à sa demande expresse, avant la fin du délai de rétractation, verse à l’Exploitant un montant correspondant au service fourni jusqu'à la communication de sa décision de se rétracter ; ce montant est proportionné au prix total de la prestation convenu dans le contrat.</p>
      <p>Aucune somme n'est due par le Client ayant exercé son droit de rétractation si sa demande expresse n'a pas été recueillie ou si l’Exploitant n'a pas respecté l'obligation d'information prévue à l'article L. 221-5 du Code de la consommation.</p>
    </section>

    <section>
      <h2>Article 9 – Service Clients</h2>
      <p>Le Client peut contacter l’Exploitant :</p>
      <ul>
        <li>au numéro suivant +33782178073, heures d’ouverture : Lundi-Samedi ; 9h-17h.</li>
        <li>par email en s’adressant à etienneleguay@query-juriste.com, en indiquant son nom, numéro de téléphone, l’objet de sa demande et le numéro de la commande concernée.</li>
      </ul>
    </section>

    <section>
      <h2>Article 10 – Propriété Intellectuelle et Licence d’Utilisation</h2>
      <p>L’Exploitant est seule titulaire des tous les éléments présents sur le Site, notamment et sans limitation, tous textes, fichiers, images animées ou non, photographies, vidéos, logos, dessins, modèles, logiciels, marques, identité visuelle, base de données, structure du Site et tous autres éléments de propriété intellectuelle et autres données ou informations. Ces éléments sont protégés par les lois et règlements français et internationaux relatifs à la propriété intellectuelle.</p>
      <p>En conséquence, aucun des Éléments du Site ne pourra en tout ou partie être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis, publié, communiqué, distribué, diffusé, représenté, stocké, utilisé, loué ou exploité de toute autre manière, sans l’autorisation préalable exprès et écrite de l’Exploitant.</p>
    </section>

    <section>
      <h2>Article 11 – Responsabilité et Garantie</h2>
      <p>L'Exploitant déclare avoir souscrit une assurance garantissant sa responsabilité professionnelle et contractuelle. Toutefois, il ne peut être tenu responsable de l'inexécution du contrat due à un tiers, à un cas de force majeure tel que défini par les tribunaux français, ou encore à cause d'un événement imprévisible et insurmontable.</p>
      <p>Le Client reconnaît que les caractéristiques et les contraintes de l'Internet ne garantissent pas la sécurité, la disponibilité et l'intégrité des transmissions de données. L’Exploitant ne garantit pas que le Site et ses services fonctionneront sans interruption ou erreur de fonctionnement; leur exploitation pourra être interrompue pour maintenance ou mise à jour.</p>
    </section>

    <section>
      <h2>Article 12 – Données Personnelles</h2>
      <p>Chaque Client est seul responsable de la préservation de la confidentialité de son identifiant et de son mot de passe, et est seul responsable de tous les accès à son Compte Client, qu’ils soient autorisés ou non.</p>
      <p>L’Exploitant ne saurait être tenu pour responsable de toute action ou fait dommageable réalisés via l’espace personnel du Client par un tiers qui aurait eu accès à ses identifiants et mot de passe suite à une faute ou une négligence étant imputable au Client. Le Client s’engage à informer l’Exploitant immédiatement dès lors que le Client a connaissance ou suspecte un usage non autorisé ou un accès non autorisé à son espace personnel.</p>
      <p>Pour davantage d’informations concernant l’utilisation de données à caractère personnel par l’Exploitant, veuillez lire attentivement la Charte sur le respect de la vie privée. Vous pouvez à tout moment consulter cette Charte sur le Site.</p>
    </section>

    <section>
      <h2>Article 13 – Liens Hypertextes</h2>
      <p>Les liens hypertextes disponibles sur le Site peuvent renvoyer vers des sites tiers non édités par l’Exploitant. Ils sont fournis uniquement pour la convenance du Client, afin de faciliter l’utilisation des ressources disponibles sur l’Internet. Si le Client utilise ces liens, il quittera le Site et acceptera alors d’utiliser les sites tiers à ses risques et périls ou le cas échéant conformément aux conditions qui les régissent.</p>
      <p>Le Client reconnait que l’Exploitant ne contrôle ni ne contribue en aucune manière à l’élaboration des conditions d’utilisation et/ou du contenu s’appliquant à ou figurant sur ces sites tiers.</p>
      <p>En conséquence, l’Exploitant ne saurait être tenu responsable de quelque façon que ce soit du fait de ces liens hypertextes.</p>
      <p>En outre, le Client reconnait que l’Exploitant ne saurait cautionner, garantir ou reprendre à son compte tout ou partie des conditions d’utilisation et/ou du contenu de ces sites tiers.</p>
      <p>Le Site peut également contenir des liens hypertextes promotionnels et/ou bandeaux publicitaires renvoyant vers des sites tiers non édités par l’Exploitant.</p>
      <p>L’Exploitant invite le Client à lui signaler tout lien hypertexte présent sur le Site qui permettrait d’accéder à un site tiers proposant du contenu contraire aux lois et/ou aux bonnes mœurs.</p>
      <p>Le Client ne pourra pas utiliser et/ou insérer de lien hypertexte pointant vers le site sans l’accord écrit et préalable de l’Exploitant au cas par cas.</p>
    </section>

    <section>
      <h2>Article 14 – Références</h2>
      <p>Le Client autorise l’Exploitant à mentionner le nom du Client, son logo à titre de référence dans ses supports de communication (plaquette, site internet, proposition commerciale, relation avec la presse, communiqué de presse, dossier de presse, communication interne, etc.).</p>
    </section>

    <section>
      <h2>Article 15 – Dispositions Générales</h2>
      <h3>Intégralité de l’Accord des Parties</h3>
      <p>Les présentes Conditions Générales constituent un contrat régissant les relations entre le Client et l’Exploitant. Elles constituent l'intégralité des droits et obligations de la Société et de l’Exploitant relatifs à leur objet. Si une ou plusieurs stipulations des présentes Conditions Générales étaient déclarées nulles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée. En outre, le fait pour une des parties aux présentes Conditions Générales de ne pas se prévaloir d'un manquement de l'autre partie à l'une quelconque des dispositions des présentes Conditions Générales ne saurait s'interpréter comme une renonciation de sa part à se prévaloir dans l'avenir d'un tel manquement.</p>

      <h3>Modifications des Conditions</h3>
      <p>L’Exploitant se réserve le droit de modifier à tout moment et sans préavis le contenu du Site ou des services qui y sont disponibles, et/ou de cesser de manière temporaire ou définitive d’exploiter tout ou partie du Site.</p>
      <p>En outre, l’Exploitant se réserve le droit de modifier à tout moment et sans préavis la localisation du Site sur l’Internet, ainsi que les présentes Conditions Générales. Le Client est donc tenu par conséquent de se reporter aux présentes Conditions Générales avant toute utilisation du Site.</p>
      <p>Le Client reconnait que l’Exploitant ne saurait être tenu responsable de quelque manière que ce soit envers lui ou tout tiers du fait de ces modifications, suspensions ou cessations.</p>
      <p>L’Exploitant conseille au Client de sauvegarder et/ou imprimer les présentes Conditions Générales pour une conservation sûre et durable, et pouvoir ainsi les invoquer à tout moment pendant l'exécution du contrat si besoin.</p>

      <h3>Réclamation - Médiation</h3>
      <p>En cas de litige, vous devez vous adresser en priorité au service client de l'entreprise aux coordonnées suivantes : <a href="mailto:etienneleguay@queryjuriste.com">etienneleguay@queryjuriste.com</a>.</p>
      <p>En cas d'échec de la demande de réclamation auprès du service client ou en l'absence de réponse de ce service dans un délai de dix (10) jours, le Client peut soumettre le différend relatif au bon de commande ou aux présentes CGV l'opposant à l’Exploitant au médiateur suivant :
      Emile-luciano Lombardo
      <a href="mailto:emilelucianolombardo@gmail.com">emilelucianolombardo@gmail.com</a>.</p>
      <p>Le médiateur tentera, en toute indépendance et impartialité, de rapprocher les parties en vue d'aboutir à une solution amiable. Les parties restent libres d'accepter ou de refuser le recours à la médiation ainsi que, en cas de recours à la médiation, d'accepter ou de refuser la solution proposée par le médiateur.</p>

      <h3>Droit Applicable</h3>
      <p>Ces Conditions Générales sont régies, interprétées et appliquées conformément au droit français.</p>

      <h3>Acceptation des Conditions Générales par le Client</h3>
      <p>Le Client reconnait avoir lu attentivement les présentes Conditions Générales.</p>
      <p>En s’inscrivant sur le Site, le Client confirme avoir pris connaissance des Conditions Générales et les accepter, le rendant contractuellement lié par les termes des présentes Conditions Générales.</p>
      <p>Les Conditions Générales applicables au Client sont celles disponibles à la date de la commande dont une copie datée à ce jour peut être remise à sa demande au Client, il est donc précisé que toute modification des Conditions Générales qui serait effectuée par l’Exploitant ne s’appliquera pas à toute commande intervenue antérieurement, sauf accord exprès du Client à l’origine d’une commande donnée.</p>
    </section>

  </div>
</template>

<script>
export default {
  name: 'ConditionsPage'
}
</script>

<style scoped>
.conditions {
  font-family: 'Arial', sans-serif;
  color: black;
  background-color: white;
  padding: 20px;
  line-height: 1.6;
  max-width: 1000px;
  margin: 40px auto;
}

.conditions h1, .conditions h2 {
  text-align: center;
  color: darkgrey;
}

.conditions h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.conditions h2 {
  font-size: 22px;
  margin-top: 40px;
}

.conditions p, .conditions li {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
}

.conditions a {
  color: black;
  textDecoration: none;
}

.conditions a:hover {
  textDecoration: underline;
}
</style>
