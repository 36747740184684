<template>
  <div class="legal-mentions">
    <h1>Mentions Légales</h1>

    <section>
      <h2>Edition du site</h2>
      <p>Le présent site, accessible à l’URL <a href="https://www.query-juriste.com">www.query-juriste.com</a>, est édité par :</p>
      <p>Query Juriste, société au capital de 100 euros, inscrite au R.C.S. de PARIS sous le numéro 929322261, dont le siège social est situé au 37 rue rousselet, représenté(e) par Etienne Leguay dûment habilité(e).</p>
    </section>

    <section>
      <h2>Hébergement</h2>
      <p>Le Site est hébergé par la société Microsoft Azure, situé 37 Quai du Président Roosevelt, 92130 Issy-les-Moulineaux, France.</p>
    </section>

    <section>
      <h2>Directeur de publication</h2>
      <p>Le Directeur de la publication du Site est Etienne Le Guay.</p>
    </section>

    <section>
      <h2>Nous contacter</h2>
      <p>Par téléphone : +33782178073</p>
      <p>Par email : <a href="mailto:etienneleguay@query-juriste.com">etienneleguay@query-juriste.com</a></p>
      <p>Par courrier : 37 rue rousselet 75007 Paris</p>
    </section>

    <section>
      <h2>Données personnelles</h2>
      <p>Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LegalMentions'
}
</script>

<style scoped>
.legal-mentions {
  font-family: 'Arial', sans-serif;
  color: black;
  background-color: white;
  padding: 20px;
  line-height: 1.6;
  max-width: 800px;
  margin: auto;
}

.legal-mentions h1 {
  text-align: center;
  font-size: 28px;
  color: darkgrey;
  margin-bottom: 20px;
}

.legal-mentions h2 {
  font-size: 20px;
  color: grey;
  margin-top: 20px;
  margin-bottom: 10px;
}

.legal-mentions p {
  font-size: 16px;
}

.legal-mentions a {
  color: black;
  text-decoration: none;
}

.legal-mentions a:hover {
  text-decoration: underline;
}
</style>
