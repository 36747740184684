<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-white p-8 shadow-md rounded-lg">
      <h2 class="text-3xl font-extrabold text-center text-gray-900">Subscribe to Our Service</h2>
      <div v-for="(product, index) in products" :key="index + '-product'" class="mt-4">
        <h3 class="text-xl font-bold">{{ product.name }}</h3>
        <div v-for="(price, priceIndex) in product.prices" :key="priceIndex + '-price'">
          <label :for="price.id" class="flex items-center mt-2">
            <input type="radio" v-model="selectedPrice" :value="price.id" :id="price.id" class="form-check-input">
            <span class="ml-2">{{ price.interval }} - {{ price.unit_amount }} / {{ price.currency }}</span>
          </label>
        </div>
      </div>
      <button @click="createSub"
              :disabled="!selectedPrice || isLoading"
              class="mt-6 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        {{ isLoading ? "Loading..." : "Create subscription" }}
      </button>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase/firebaseConfig"; // Check the correct path
import { getFirestore, collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { onSnapshot } from "firebase/firestore";

// Replace 'YOUR_PUBLIC_STRIPE_KEY' with your actual Stripe publishable key
const stripePromise = loadStripe('pk_live_51PQxB7RqxJuRWeFcXH1CTaLf98VIhslXlItnLaV7BVTooIvZg6SmEY34cojcg8iDWYLp43Z6LaSIriJtCHRpElST000myRWNMH');

export default {
  data() {
    return {
      products: [],
      selectedPrice: null,
      isLoading: false,
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      const db = getFirestore();
      const productsRef = collection(db, "products");
      const activeProductsQuery = query(productsRef, where("active", "==", true));
      const productsQuerySnap = await getDocs(activeProductsQuery);

      // We'll use an array to track all asynchronous operations
      let promises = [];

      productsQuerySnap.forEach((doc) => {
        const pricesRef = collection(db, "products", doc.id, "prices");
        const pricesPromise = getDocs(pricesRef).then(pricesQuerySnap => {
          return {
            id: doc.id,
            ...doc.data(),
            prices: pricesQuerySnap.docs.map(priceDoc => ({
              id: priceDoc.id,
              ...priceDoc.data(),
            })),
          };
        });

        // Push each promise into the promises array
        promises.push(pricesPromise);
      });

      // Wait for all promises to resolve before setting the products data
      Promise.all(promises).then(products => {
        this.products = products;
      }).catch(error => {
        console.error("Error fetching products:", error);
      });
    },

    async createSub() {
      if (!auth.currentUser) {
        console.error("No user logged in");
        return;
      }
      this.isLoading = true;
      const stripe = await stripePromise;
      const db = getFirestore();
      const checkoutSessionsRef = collection(db, "customers", auth.currentUser.uid, "checkout_sessions");

      try {
        const docRef = await addDoc(checkoutSessionsRef, {
          price: this.selectedPrice,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            if (data.error) {
              console.error(`An error occurred: ${data.error.message}`);
              this.isLoading = false;
            } else if (data.sessionId) {
              stripe.redirectToCheckout({ sessionId: data.sessionId });
            }
          }
        });
      } catch (error) {
        console.error('Checkout error:', error);
        this.isLoading = false;
      }
    },
  }
};
</script>

<style scoped>
</style>
