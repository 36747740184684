import { render, staticRenderFns } from "./mentionsPage.vue?vue&type=template&id=dc57121c&scoped=true"
import script from "./mentionsPage.vue?vue&type=script&lang=js"
export * from "./mentionsPage.vue?vue&type=script&lang=js"
import style0 from "./mentionsPage.vue?vue&type=style&index=0&id=dc57121c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc57121c",
  null
  
)

export default component.exports