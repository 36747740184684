<template>
  <div v-if="anyModalVisible" class="modal-container">
    <button class="close-button" @click="closeModal">×</button>
    <div v-for="(product, index) in products" :key="index + '-product-modal'">
      <div v-if="visible[index]" class="modal-overlay">
        <div class="modal-content"
        :class="{'small-font': product.name === 'Query Juriste Essentiel'}"
        :style="product.name === 'Query Juriste Essentiel' ? { width: '300px', height: '430px' } : { width: '370px', height: '450px' }"
        >
          <div class="logo">
            <img class="img-logo" src="../assets/favicon-j.png" alt="logo">
          </div>
          <div class="price-container">
            <span class="price-text">{{ product.name === 'Query Juriste Standard' ? '19,99€' : '9,99€' }}</span>
            <p class="price-subtext">Par mois</p>
          </div>
          <div>
            <h2 class="product-title">Query Juriste</h2>
            <h2 class="product-title">{{ product.name === 'Query Juriste Standard' ? 'Standard' : 'Etudiant' }}</h2>
          </div>
          <div class="description-container">
            <p class="text-center text-gray-900">{{ product.name === 'Query Juriste Standard' ? "Bénéficiez d'un nombre illimité de requêtes par jour." : 'Accédez à 10 requêtes par jour.' }}</p>
          </div>
          <button @click="createSub(product.prices[0].id)"
                  :disabled="isLoading"
                  class="custom-subscribe-button">
            {{ isLoading ? "Chargement..." : "S'abonner" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { onSnapshot } from "firebase/firestore";
import { auth } from "@/firebase/firebaseConfig";

const stripePromise = loadStripe('pk_live_51PQxB7RqxJuRWeFcXH1CTaLf98VIhslXlItnLaV7BVTooIvZg6SmEY34cojcg8iDWYLp43Z6LaSIriJtCHRpElST000myRWNMH');

export default {
  props: ['visible', 'products'],
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    anyModalVisible() {
      return this.visible.some(v => v); // Checks if any index in `visible` is true
    },
  },

  methods: {
    async createSub(priceId) {
      this.isLoading = true;
      try {
        const stripe = await stripePromise;
        const db = getFirestore();
        const checkoutSessionsRef = collection(db, "customers", auth.currentUser.uid, "checkout_sessions");
        const docRef = await addDoc(checkoutSessionsRef, {
          price: priceId,
          success_url: window.location.origin + '/homePage',
          cancel_url: window.location.origin + '/homePage',
        });
        onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            const { error, sessionId } = doc.data();
            if (error) {
              console.error(`An error occurred: ${error.message}`);
            } else if (sessionId) {
              stripe.redirectToCheckout({ sessionId });
            }
          }
        });
      } catch (error) {
        console.error('Checkout error:', error);
        this.isLoading = false;
      }
    },
    closeModal() {
      this.$emit('requestCloseModal'); // Emitting an event
    },
    handleCloseModal(index) {
      this.$set(this.visible, index, false); // Vue.set for reactivity in case needed
    },
  }
};
</script>

<style scoped>

.custom-subscribe-button {
  background-color: black;
  color: white;
  padding: 1rem 3rem; /* Adjust padding as needed */
  border-radius: 0.375rem; /* This is equivalent to 'rounded-md' */
  font-size: 1rem; /* Smaller font size */
  transition: background-color 0.3s ease-in-out; /* Smooth transition for hover effects */
  outline: none; /* Removes outline to match 'focus:outline-none' */
  /* Focus Ring */
}

.custom-subscribe-button:hover:not(:disabled) {
  background-color: lightgray; /* Hover effect */
  opacity: 0.9;
}

.custom-subscribe-button:disabled {
  background-color: gray; /* Disabled state */
  cursor: not-allowed;
}

.custom-subscribe-button:focus {
  /* Focus state, replicating 'focus:ring-offset-2' effect */
  box-shadow: 0 0 0 2px white, 0 0 0 4px rgba(59, 130, 246, 0.5);
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 10px;
  font-family: 'Helvetica', sans-serif;
  justify-content: flex-start; /* Align modals to the left */
  padding-left: 18%;
}

.modal-overlay {
  flex: 1; /* Each modal takes equal width */
  display: flex;
  justify-content: center;
}

.modal-content {
  margin: 10px;
  font-family: 'Helvetica', sans-serif;
  background: #f6f6f6;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Adjusted for visibility within flex container */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0; /* Remove space below logo */
}

.img-logo {
  width: 65px;
  height: 65px;
}

.small-font {
  font-size: 0.875rem; /* Adjust this value as needed */
}

.modal-content.small-font {
  font-size: 0.95em; /* Slightly smaller text overall in the modal */
}

.price-text.small-font {
  font-size: 40px; /* Smaller price text for 'Essentiel' */
}

.product-title.small-font {
  font-size: 1.75rem; /* Smaller product title */
}

.price-container {
  text-align: center;
  margin-top: 0; /* Remove space above price container */
}

.price-text {
  font-size: 45px;
  font-weight: bold;
  color: black;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.product-title {
  font-size: 1.875rem; /* Equivalent to 3xl */
  font-weight: 500; /* Equivalent to extrabold */
  text-align: center;
  color: #1f2937; /* Equivalent to gray-900 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.price-subtext {
  font-size: 16px; /* Smaller font size for the subtext */
  color: #4A5568; /* Dim color for less emphasis */
  text-align: center; /* Center align to match the price text */
}


.product-container {
  margin-top: 20px;
}

.price-options {
  display: flex;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

.subscribe-button, .close-button {
  transition: background-color 0.2s;
  font-weight: bold;
  cursor: pointer;
}

.subscribe-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.subscribe-button:hover:not(:disabled) {
  background-color: lightgray !important; /* Changed to light gray */
  opacity: 0.9;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

</style>
