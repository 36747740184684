<template>
  <div class="connection-background">
    <div class="form-container">

      <img src="../assets/favicon-j.png" alt="logo" style="width: 18%; height: 20%; margin-bottom: 20px;">

      <form @submit.prevent="signin" class="form-horizontal">
        <div class="form-inputs">
          <div class="form-group">
            <input type="email"
                   v-model="email"
                   required
                   autofocus
                   autocomplete="email"
                   class="form-control"
                   :class="{ 'error-input': loginError }"
                   placeholder="E-mail">
          </div>
          <div class="form-group">
            <input type="password"
                   v-model="password"
                   required
                   class="form-control"
                   :class="{ 'error-input': loginError }"
                   autocomplete="current-password"
                   placeholder="Mot de passe">
            <div v-if="loginError" class="error-message">
              Il semble que cet e-mail est déjà utilisé
            </div>
          </div>
        </div>

        <div class="form-actions">
          <button type="submit" class="submit-button">Inscription</button>
        </div>

        <div class="signup-link">
          Deja un compte ? <router-link to="/loginPage">Se connecter</router-link>
        </div>
      </form>

    </div>

    <button @click="signInWithGoogle" class="oauth-button google">
      <img src="@/assets/google-logo.png" alt="Google Logo" class="google-logo">
      <span>Continuer avec Google</span>
    </button>

  </div>
</template>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.google-logo {
  width: 20px; /* Adjusted for size */
  height: 20px; /* Adjusted for size */
  vertical-align: middle; /* Align with text */
  margin-right: 8px; /* Space between logo and text */
}

.connection-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}
.form-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 400px;
  border-radius: 10px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container h2 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.form-horizontal .form-group {
  margin-bottom: 15px;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
  width: 100%; /* Updated width */
  padding: 5px 10px;
}

.submit-button {
  width: 100%; /* Remains unchanged, already 100% */
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #7e5cf2;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}


.submit-button:hover {
  background-color: #6941c6;
}

.error-input {
  border: 1px solid red; /* Red border for error inputs */
}

.error-message {
  color: red; /* Red text for error message */
  font-size: 0.8em; /* Smaller font size for error message */
  margin-top: 5px; /* Space between input and error message */
}

@media (max-width: 768px) {
  .form-container {
    width: auto;
    padding: 15px;
  }
}

.signup-link {
  margin-top: 20px;
  text-align: center;
}

/* Optionally style the router-link directly if needed */
.signup-link a {
  color: #7e5cf2;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.oauth-button {
  display: inline-flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #7e5cf2;
  border-radius: 50px;
  background-color: white;
  color: #7e5cf2;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

.oauth-button.google:hover {
  background-color: white; /* Keeping original background color */
  color: #6941c6; /* Keeping original text color */
  transform: scale(1.05); /* Scales the button to 105% of its original size */
}

@media (max-width: 768px) {
  .connection-background {
    padding-top: 10px; /* Remove padding at the top for mobile */
  }

  .form-container {
    width: 80%; /* Make the form width relative to the screen size */
    padding: 40px 20px; /* Reduce padding for smaller screens */
  }

  .form-container h2 {
    font-size: 1.5em; /* Adjust font size for mobile screens */
  }
}

</style>


<script>
import { signUp, signInWithGoogle } from '@/firebase/auth';

export default {

  data() {
    return {
      email: '',
      password: '',
      loginError: false
    };
  },
  methods: {
    async signInWithGoogle() {
      try {
        await signInWithGoogle();
        this.$router.replace({ name: 'home' });
      } catch (error) {
        alert(error.message);
      }
    },

    async signin() {
      try {
        await signUp(this.email, this.password);
        this.$router.replace({ name: 'home' });
        this.loginError = false;
      } catch (error) {
        // Consider using a more user-friendly error handling approach
        this.loginError = true;
      }
    }
  }
}
</script>
