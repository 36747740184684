import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/tailwind.css';
import { auth } from './firebase/firebaseConfig';

Vue.config.productionTip = false;

let app;

auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  }

  if (user) {
    if (user.emailVerified) {
      // Redirect logged-in and verified users away from login/sign-in pages
      if (router.currentRoute.path === '/loginPage' || router.currentRoute.path === '/signinPage' || router.currentRoute.path === '/') {
        router.push('/homePage'); // Default landing page for verified users
      }
    } else {
      // Allow access to the about page even if the user's email is not verified
      if (router.currentRoute.path !== '/' && router.currentRoute.path !== "/verify-email") {
        const currentUrl = window.location.href;
        const isVerificationProcess = currentUrl.includes("VerifiedEmail");

        // Redirect to email verification page if the email isn't verified and not in the verification process
        if (!isVerificationProcess) {
          router.push("/verify-email");
        }
      }
    }
  } else {
    // Only redirect to login if not accessing public routes like root or signinPage
    if (!['/', '/loginPage', '/signinPage'].includes(router.currentRoute.path)) {
      router.push('/loginPage');
    }
  }
});
