// src/services/api.js

import { app } from '@/firebase/firebaseConfig';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';


const auth = getAuth(app);

// Function to fetch the current user's Firebase authentication token
async function getFirebaseToken() {
  return auth.currentUser ? await auth.currentUser.getIdToken() : null;
}

export { getFirebaseToken };
