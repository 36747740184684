<template>
  <div class="big-container">
    <!-- Image Container -->
    <div class="image-container">
      <img src='@/assets/freepik-connect.jpg' alt="Cloudinary Image">
    </div>
    <!-- Container for Tagline and Buttons -->
    <div class="container-tag-buttons">
      <div class="tagline-container">
        <h2>Parcourez la jurisprudence Française plus rapidement</h2>
      </div>
      <div class="buttons-container">
        <router-link to="/loginPage" class="button login-button">Connexion</router-link>
        <router-link to="/signinPage" class="button signup-button">Inscription</router-link>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'HomePage',
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.big-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 100vh;
}

.image-container {
  flex: 1; /* Take up half the space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%; /* Adjust based on your preference */
  height: auto; /* Maintain aspect ratio */
}

.container-tag-buttons {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tagline-container h2 {
  font-size: 2em;
  color: #FF5733;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-family: 'Roboto', sans-serif;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button {
  background-color: #3498DB;
}

.signup-button {
  background-color: #3498DB;
}

.login-button:hover,
.signup-button:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .image-container {
    display: none; /* Hide the image container */
  }

  .big-container {
    flex-direction: column; /* Stack the elements vertically */
    justify-content: center; /* Center the content vertically */
    background-color: #FDF8F5;
  }

  .container-tag-buttons {
    width: 100%; /* Use the full width */
    padding: 10px; /* Adjust padding */
  }

  .tagline-container h2 {
    font-size: 1.5em; /* Adjust font size for smaller screens */
  }

  .buttons-container {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Reduce gap between buttons */
  }
}
</style>
