import Vue from "vue";
import Router from "vue-router";
import HomePage from "@/components/HomePage.vue";
import LoginPage from "@/components/LoginPage.vue";
import SigninPage from "@/components/SigninPage.vue";
import FirstPage from "@/components/FirstPage.vue";
import about from "@/components/about.vue";
import testPage from "@/components/testPage.vue";
import EmailVerificationAlert from "@/components/EmailVerificationAlert.vue";
import EmailVerified from "@/components/VerifiedEmail.vue";
import SuccessPage from "@/components/SuccessPage.vue";
import CancelPage from "@/components/CancelPage.vue";
import subscriptionPage from "@/components/subscriptionPage.vue";
import usageGuide from "@/components/usageGuide.vue";
import mentionsPage from "@/components/mentionsPage.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import conditionsGeneralePage from "@/components/conditionsGeneralePage.vue";
import { app } from "@/firebase/firebaseConfig";
// import checkSubscriptionStatus from "@/middleware/subscription";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    { path: "/loginPage", name: "login", component: LoginPage },
    { path: "/conditionsGeneralePage", name: "conditionsGenerale", component: conditionsGeneralePage },
    { path: "/mentionsPage", name: "mentions", component: mentionsPage },
    { path: "/testPage", name: "test", component: testPage },
    { path: "/VerifiedEmail", name: "verifiedEmail", component: EmailVerified },
    { path: "/", name: "about", component: about },
    { path: "/homePage", name: "home", component: HomePage, meta: { requiresAuth: true } },
    { path: "/signinPage", name: "signin", component: SigninPage },
    { path: "/firstPage", name: "first", component: FirstPage },
    { path: "/usageGuide", name: "usageGuide", component: usageGuide },
    { path: "/verify-email", name: "verifyEmail", component: EmailVerificationAlert },
    { path: "/subscriptionPage", name: "subscription", component: subscriptionPage },
    { path: "/success", name: "success", component: SuccessPage },
    { path: "/cancel", name: "cancel", component: CancelPage }
  ]
});

const auth = getAuth(app);

let isAuthenticated = false;
let authCheckPromise = new Promise((resolve) => {
  onAuthStateChanged(auth, (user) => {
    isAuthenticated = !!user;
    resolve();
  });
});

router.beforeEach((to, from, next) => {
  authCheckPromise.then(async () => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const user = auth.currentUser;
    const userVerified = user ? user.emailVerified : false;

    if (from.path === '/homePage') {
      console.log('Navigating away from the home page');
      next();
    } else if (requiresAuth && !isAuthenticated) {
      next("/loginPage");
    } else if (requiresAuth && isAuthenticated && !userVerified) {
      // Allow access to the about page even if the user's email is not verified
      if (to.path === '/') {
        next(); // Continue to the about page
      } else {
        // Redirect to verify email page for other authenticated routes
        next("/verify-email");
      }
    } else if (!requiresAuth && isAuthenticated && userVerified && (to.path === "/loginPage" || to.path === "/signinPage" || to.path === "/firstPage")) {
      next("/homePage");
    } else {
      next(); // Continue as normal for all other conditions
    }
  });
});




export default router;
