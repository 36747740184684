<template>
  <div class="email-verification-container">
    <h1>Verification</h1>
    <p v-if="verified" class="success-message">
      Votre email a bien été vérifié!
    </p>
    <div v-if="verified" class="get-started-link">
      <a href="https://www.query-juriste.com/homePage">Commencer maintenant</a>
    </div>
  </div>
</template>

<script>
import { getAuth, applyActionCode } from 'firebase/auth';

export default {
  data() {
    return {
      verified: false,
      error: false,
      errorMessage: ''
    };
  },
  mounted() {
    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');

    if (mode === 'verifyEmail') {
      applyActionCode(auth, actionCode)
        .then(() => {
          this.verified = true;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
        });
    }
  }
}
</script>

<style scoped>
.email-verification-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h1 {
  color: #333;
  font-family: Arial, sans-serif;
}

.success-message, .error-message, .verifying-message {
  font-size: 16px;
  color: #666;
  margin: 10px 0;
}

.success-message {
  color: #28a745;
}

.error-message {
  color: #dc3545;
}

.get-started-link a {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.get-started-link a:hover {
  background-color: #0056b3;
}
</style>
