<template>
  <div v-if="visible" class="error-modal">
    <div class="modal-container">
      <button class="close-button" @click="closeModal">×</button>
      <div class="modal-overlay">
        <div class="modal-content">
          <div class="logo">
            <img class="img-logo" src="../assets/favicon-j.png" alt="logo">
          </div>
          <div>
            <h2 class="product-title">Query Juriste</h2>
          </div>
          <div class="description-container">
            <p class="text-center text-gray-900">Inscrivez-vous pour continuer.</p>
          </div>
          <button @click="register" class="custom-subscribe-button">S'inscrire</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    errorMessage: String
  },
  methods: {
    register() {
      // You can implement registration logic here or navigate to a registration page
      this.$emit('register');
    }
  }
}
</script>

<style scoped>

.custom-subscribe-button {
  background-color: black;
  color: white;
  padding: 1rem 3rem; /* Adjust padding as needed */
  border-radius: 0.375rem; /* This is equivalent to 'rounded-md' */
  font-size: 1rem; /* Smaller font size */
  transition: background-color 0.3s ease-in-out; /* Smooth transition for hover effects */
  outline: none; /* Removes outline to match 'focus:outline-none' */
  /* Focus Ring */
}

.custom-subscribe-button:hover:not(:disabled) {
  background-color: lightgray; /* Hover effect */
  opacity: 0.9;
}

.custom-subscribe-button:disabled {
  background-color: gray; /* Disabled state */
  cursor: not-allowed;
}

.custom-subscribe-button:focus {
  /* Focus state, replicating 'focus:ring-offset-2' effect */
  box-shadow: 0 0 0 2px white, 0 0 0 4px rgba(59, 130, 246, 0.5);
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 10px;
  font-family: 'Helvetica', sans-serif;
  justify-content: flex-start; /* Align modals to the left */
}

.modal-overlay {
  flex: 1; /* Each modal takes equal width */
  display: flex;
  justify-content: center;
}

.modal-content {
  margin: 10px;
  font-family: 'Helvetica', sans-serif;
  background: #f6f6f6;
  padding: 20px;
  border-radius: 8px;
  width: 25vw; /* Adjusted for visibility within flex container */
  height: 50vh; /* Adjusted for visibility within flex container */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0; /* Remove space below logo */
}

.img-logo {
  width: 65px;
  height: 65px;
}

.small-font {
  font-size: 0.875rem; /* Adjust this value as needed */
}

.modal-content.small-font {
  font-size: 0.95em; /* Slightly smaller text overall in the modal */
}

.price-text.small-font {
  font-size: 40px; /* Smaller price text for 'Essentiel' */
}

.product-title.small-font {
  font-size: 1.75rem; /* Smaller product title */
}

.price-container {
  text-align: center;
  margin-top: 0; /* Remove space above price container */
}

.price-text {
  font-size: 45px;
  font-weight: bold;
  color: black;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.product-title {
  font-size: 1.875rem; /* Equivalent to 3xl */
  font-weight: 500; /* Equivalent to extrabold */
  text-align: center;
  color: #1f2937; /* Equivalent to gray-900 */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.price-subtext {
  font-size: 16px; /* Smaller font size for the subtext */
  color: #4A5568; /* Dim color for less emphasis */
  text-align: center; /* Center align to match the price text */
}


.product-container {
  margin-top: 20px;
}

.price-options {
  display: flex;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

.subscribe-button, .close-button {
  transition: background-color 0.2s;
  font-weight: bold;
  cursor: pointer;
}

.subscribe-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.subscribe-button:hover:not(:disabled) {
  background-color: lightgray !important; /* Changed to light gray */
  opacity: 0.9;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

</style>
